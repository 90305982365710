/* .App {
  text-align: center;
} */

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #242830;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.json-container{
  display: block;
white-space: pre;
margin: 0px;
}
.jsonOutput{
  height: 350px;
  width: 800px;
  border: 5px solid silver;
  padding: 5px 10px;
  transition: background-color 400ms;
  overflow: scroll;
}
.countryField{
  font-size: 14px;
  position: relative;
  padding: 18px;
  border-radius: 8px;
  width: 200px;
}
.submitBtn{
  padding: 18px;
border-radius: 8px;
color: white;
background-color: #242830
}
.loader{
  width: 100%;
  height: 50px;
  background: #242830
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
}
.codeBar{
  display: block;
  overflow-x: auto;
  padding: .5em;
  background: #282b2e;
  color: white;
}